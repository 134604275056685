import React from 'react'
import styled from 'styled-components'
import { lighten } from 'polished'

// Components
import Wrapper from './wrapper'
import Header from './header'

export default () => {
    const hours = [
        {
            day: `Monday`,
            open: `8:00`,
            close: `5:00`
        }, {
            day: `Tuesday`,
            open: `8:00`,
            close: `5:00`
        }, {
            day: `Wednesday`,
            open: `8:00`,
            close: `5:00`
        }, {
            day: `Thursday`,
            open: `8:00`,
            close: `5:00`
        }, {
            day: `Friday`,
            open: `8:00`,
            close: `5:00`
        }
    ]
    return (
        <Wrapper>
            <Header title={`Open Hours`}/>
            <Description>{`The following hours may not include holiday hours.`}</Description>
            <List>
            {hours.map(({
                day,
                open,
                close
            }, index) => (
                <Item key={index}>
                    <Day>{`${day}: `}</Day>
                    <Time>{`${open} AM - ${close} PM`}</Time>
                </Item>
            ))}
            </List>
        </Wrapper>
    )
}

// Styled Components
const Description = styled.p`
    line-height: 1.15;
`
const List = styled.ul`
    margin:0;
    padding:0;
    list-style:none;
    display:grid;
    grid-template-columns:1fr;
`
const Item = styled.li`
    display:flex;
    flex-wrap:wrap;
    justify-content:space-between;
    font-size:0.85rem;
    padding:0.5rem;
    font-weight:bold;
    line-height:1;
    color:${props => props.theme.color.blue.dark};
    &:nth-child(odd) {
        ${props => `
            background-color:${lighten(0.4, props.theme.color.blue.primary)};
        `}
    }
`
const Day = styled.span`
    font-weight:bold;
`
const Time = styled.span`
    opacity:0.85;
`