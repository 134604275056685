import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

export default ({
    menuLinks,
    open
}) => {
    return (
        <List open={open}>
            {menuLinks.map(({ name, link }) => (
                <Item key={name}>
                    <ItemLink 
                        to={link}
                    >
                        {name}
                    </ItemLink>
                </Item>
            ))}
            <Item>
                <ItemLink 
                    to={`/quote/`}
                >
                    {`Request a Quote`}
                </ItemLink>
            </Item>
        </List>
    )
}


// Styled Components
const List = styled.ul`
    position:relative;
    width:100%;
    margin:0;
    padding:0;
    list-style:none;
    text-align:center;
    display:grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding-top:1px;
    grid-column-gap:1px;
    grid-row-gap:1px;
    margin-top:-50%;
    z-index:-1;
    transition:all .2s;
    background-color:#f1f1f1;
    opacity:0;
    box-shadow: 0 1px 5px rgba(0,0,0,.17);
    ${props => props.open ? `
        opacity:1;
        margin-top:0;
    ` : `
    
    `}
    @media (min-width:1200px) {
        display:none;
    }
`
const Item = styled.li`
    background-color:#fff;
    margin:0;
    padding:0;
    display:inline-block;
    height:100%;
    &:last-child {
        margin-right:0;
    }
`
const ItemLink = styled(Link)`
    height:100%;
    display:block;
    font-size:0.75rem;
    font-weight:bold;
    text-transform:uppercase;
    letter-spacing:1px;
    padding:0 0.5rem;
    ${props => `
        line-height:${props.theme.header.mobile}px;
        color:${props.theme.color.blue.dark};
        &:hover {
            box-shadow:inset 0 -4px 0 0 ${props.theme.color.blue.primary};
        }
        @media (min-width:1200px) {
            line-height:${props.theme.header.desktop}px;
        }
    `}
`
