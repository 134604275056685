import { lighten, darken } from 'polished'

export default {
    color: {
        blue: {
            primary: "#406fc7",
            light: `${lighten(0.15, `#406fc7`)}`,
            dark: `${darken(0.3, `#406fc7`)}`,
            overlay: `${lighten(0.4, `#406fc7`)}`,
        },
        red: {
            primary: "#d50000",
            light: `${lighten(0.05, `#d50000`)}`,
            dark: `${darken(0.15, `#d50000`)}`
        }
    },
    gutter: "1.5rem",
    header: {
        desktop: 90,
        mobile: 70
    }
}