import { useStaticQuery, graphql } from 'gatsby'

export default () => {
    const { site: { siteMetadata } } = useStaticQuery(graphql`
        query useSiteMetadata {
            site {
                siteMetadata {
                    title
                    menuLinks {
                      name
                      link
                    }
                }
            }
        }
    `)

    return siteMetadata
}