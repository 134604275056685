import React from 'react'
import styled, { ThemeProvider } from 'styled-components'

// Components
import Header from '../header/index'
import Footer from '../footer/index'

// Theme
import Theme from './theme'

// Hooks
import useSiteMetadata from '../../hooks/meta/useSiteMetadata'

// Style
import './style.css'

export default ({ children }) => {

    const { menuLinks } = useSiteMetadata()

    return (
        <ThemeProvider theme={Theme}>
            <Header menuLinks={menuLinks}/>
                <Main>
                    {children}
                </Main>
            <Footer menuLinks={menuLinks}/>
        </ThemeProvider>
    )
}

// Styled Components
const Main = styled.main`
    position:relative;
    ${props => `
        margin-top:${props.theme.header.mobile}px;
        @media (min-width:1200px) {
            margin-top:${props.theme.header.desktop}px;
        }
    `}
`