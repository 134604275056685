import React from 'react'
import styled from 'styled-components'

// Components
import Container from '../container'
import About from './about'
import SiteMap from './sitemap'
import Hours from './hours'
import Contact from './contact'
import Copyright from './copyright'
import Social from './social'

export default ({
    menuLinks
}) => {
    return (
        <Footer>
            <Container
                sm={`
                    display:grid;
                    grid-row-gap:1.5rem;
                    padding-top:3rem;
                    padding-bottom:3rem;
                `}
                lg={`
                    grid-template-columns:repeat(4, minmax(0, 1fr));
                    grid-column-gap:3rem;
                `}
            >
                <About/>
                <SiteMap menuLinks={menuLinks}/>
                <Hours/>
                <Contact/>
            </Container>
            <Container
                sm={`
                    display:grid;
                    box-shadow: inset 0px 5px 2px -5px rgba(0,0,0,0.35);
                    grid-template-columns: minmax(0,1fr);
                    padding-top:0.75rem;
                    padding-bottom:0.75rem;
                    grid-row-gap:0.75rem;
                `}
                lg={`
                    justify-content:space-between;
                    padding-top:1.5rem;
                    padding-bottom:1.5rem;
                    align-items:center;
                `}
            >
                <Social/>
                <Copyright/>
            </Container>
        </Footer>
    )
}

// Styled Components
const Footer = styled.footer`

`