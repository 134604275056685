import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

export default ({
    menuLinks
}) => {
    return (
        <List>
            {menuLinks.map(({ name, link }) => (
                <Item key={name}>
                    <ItemLink 
                        to={link}
                    >
                        {name}
                    </ItemLink>
                </Item>
            ))}
        </List>
    )
}


// Styled Components
const List = styled.ul`
    margin:0;
    padding:0;
    list-style:none;
    text-align:center;
    display:none;
    @media (min-width:1200px) {
        display:block;
    }
`
const Item = styled.li`
    margin:0 1rem 0 0;
    padding:0;
    display:inline-block;
    height:100%;
    &:last-child {
        margin-right:0;
    }
`
const ItemLink = styled(Link)`
    height:100%;
    display:block;
    font-size:0.75rem;
    font-weight:bold;
    text-transform:uppercase;
    letter-spacing:1px;
    padding:0 0.5rem;
    ${props => `
        line-height:${props.theme.header.mobile}px;
        color:${props.theme.color.blue.dark};
        &:hover {
            box-shadow:inset 0 -4px 0 0 ${props.theme.color.blue.primary};
        }
        @media (min-width:1200px) {
            line-height:${props.theme.header.desktop}px;
        }
    `}
`
