import React from 'react'
import styled from 'styled-components'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'


export default () => {
    const { file } = useStaticQuery(graphql`
        query headerLogoQuery {
            file(relativeDirectory: {eq: "logo"}, name: {eq: "logo-large"}) {
                childImageSharp {
                    fixed(height:60) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)

    const logo = file.childImageSharp.fixed
    return (
        <Link to="/">
            <Logo fixed={logo} />
        </Link>
    )
}

// Styled Components
const Logo = styled(Img)`
    display:block;
    margin:0;
    padding:0;
`