import React from 'react'
import styled from 'styled-components'

export default ({ children }) => (
    <Wrapper>
        {children}
    </Wrapper>
)

// Styled Components
const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;
    align-content: start;
`