import React from 'react'
import styled from 'styled-components'
import { HamburgerSlider } from 'react-animated-burgers'

export default ({
    open,
    toggleOpen
}) => (
    <Button isActive={open} toggleButton={() => toggleOpen(!open)} barColor={`#192e55`}/>
)

// Styled Components
const Button = styled(HamburgerSlider)`
    justify-self:flex-end;
    height:24px;
    padding:0;
    outline:0;
    @media (min-width:1200px) {
        display:none;
    }
`
