import React from 'react'
import styled from 'styled-components'

export default ({
    title
}) => {
    return (
        <Title>{title}</Title>
    )
}

// Styled Components
const Title = styled.h2`
    text-transform:uppercase;
    font-size:1.5rem;
    color:${props => props.theme.color.blue.dark};
`