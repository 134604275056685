import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

// Components
import Wrapper from './wrapper'

export default () => {
    const { file } = useStaticQuery(graphql`
        query footerLogoQuery {
            file(relativeDirectory: {eq: "logo"}, name: {eq: "logo-large"}) {
                childImageSharp {
                    fixed(height:95) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)

    const logo = file.childImageSharp.fixed
    return (
        <Wrapper>
            <Title>
                <Logo 
                    fixed={logo}
                    alt={``}
                    title={`About Us`}
                />
            </Title>
            <Description>{`JP Home Builders can handle all your design needs. Give us a call today.`}</Description>
            <List>
                <Item>
                    <Subtitle>Address</Subtitle>
                    <Info>{`945 Alpine Road, Marion, Iowa 52240`}</Info>
                </Item>
                <Item>
                    <Subtitle>Phone</Subtitle>
                    <Info>{`(319) 423-8191`}</Info>
                </Item>
            </List>
        </Wrapper>
    )
}

// Styled Components
const Title = styled.h2`

`
const Description = styled.p`
    line-height:1.15;
`
const Logo = styled(Img)`
    height:100px;
`
const List = styled.ul`
    list-style:none;
    margin:0;
    padding:0;
    display:grid;
    grid-template-columns:1fr;
    grid-row-gap:1.5rem;
`
const Item = styled.li`
    width:100%;
    display:flex;
    justify-content:space-between;
`
const Subtitle = styled.h3`
    font-size:0.9rem;
    line-height:1.15;
    color:${props => props.theme.color.blue.dark};
`
const Info = styled.p`
    font-size:0.9rem;
    line-height:1.15;
    width:155px;
`