import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

// Components
import Container from '../container'
import Logo from './logo'
import Cta from './cta'
import Menu from './menu'
import MobileMenu from './mobileMenu'
import Hamburger from './hamburger'

export default ({
    menuLinks
}) => {
    const [open, toggleOpen] = useState(false)
    const [scroll, toggleScroll] = useState(false)

    useEffect(() => {
        if (typeof window !== `undefined`) {

            const handleScroll = () => {
                const show = window.scrollY > 1
                
                if (show && !scroll) {
                    return toggleScroll(true)
                } else if (!show && scroll) {
                    return toggleScroll(false) 
                } else {
                    return
                }
            }

            handleScroll()

            window.addEventListener('scroll', handleScroll)

            return () => {
                window.removeEventListener('scroll', handleScroll)
            }
        }
    }, [ scroll ])

    return (
        <Wrapper scroll={scroll}>
            <Container
                sm={`
                    display:grid;
                    grid-template-columns: 1fr 1fr;
                    height:100%;
                    align-items:center;
                    background-color:#fff;
                `}
                xl={`
                    grid-template-columns: 1fr 2fr 1fr;
                    height:100%;
                    align-items:center;
                `}
            >
                <Logo/>
                <Menu menuLinks={menuLinks}/>
                <Cta />
                <Hamburger 
                    open={open}
                    toggleOpen={toggleOpen}
                />
            </Container>
            <MobileMenu 
                menuLinks={menuLinks}
                open={open}
            />
        </Wrapper>
    )
}

// Styled Components
const Wrapper = styled.header`
    position: fixed;
    width:100%;
    background-color:#fff;
    z-index:9;
    top:0;
    box-shadow:none;
    transition: all .2s;
    ${props => `
        height: ${props.theme.header.mobile}px;
        @media (min-width:1200px) {
            height: ${props.theme.header.desktop}px;
        }
        ${props.scroll ? `
            box-shadow: 0 1px 5px rgba(0,0,0,.17);
        ` : ``}
    `}
`