import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

export default () => {
    return (
        <Button as={Link} to={`/quote/`}>{`Get a Free Quote`}</Button>
    )
}

// Styled Components
const Button = styled.a`
    display:none;
    justify-self: end;
    padding:0.75rem 1.5rem;
    text-transform:uppercase;
    font-weight:bold;
    font-size:0.85rem;
    letter-spacing:1px;
    text-align:center;
    color:#fff;
    &:hover {
        box-shadow:0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0,0,0,.12)
    }
    ${props => `
        background-color:${props.theme.color.blue.primary};
        box-shadow:0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
    `}
    @media (min-width:1200px) {
        display:inline-block;
    }
`