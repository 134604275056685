import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import Wrapper from './wrapper'
import Header from './header'

export default () => {
    const { file } = useStaticQuery(graphql`
        query footerMapQuery {
            file(relativeDirectory: {eq: "footer"}, name: {eq: "map"}) {
                childImageSharp {
                    fluid(maxHeight:120) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    const map = file.childImageSharp.fluid
    return (
        <Wrapper>
            <Header title={`Find Us`}/>
            <Description>{`Stop in and visit our store location.`}</Description>
            <Location fluid={map} alt={``}/>
        </Wrapper>
    )
}

// Styled Components
const Description = styled.p`
    line-height: 1.15;
`
const Location = styled(Img)`
    border-radius:0.5rem;
    overflow:hidden;
    box-shadow:rgba(14,30,37,0.12) 0px 2px 4px 0px;
    height:165px;
    max-width:252px;
`