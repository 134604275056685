import React from 'react'
import styled from 'styled-components'


export default () => {
    const date = new Date().getFullYear()
    return (
        <Description>
            {`Copyright © ${date} J.P. Home Builder's Corp. `}
            <Link href="https://espercreations.com" target="_blank">{`Website Crafted by Esper Creations.`}</Link>
        </Description>
    )
}

// Styled Components
const Description = styled.p`
    font-size:0.85rem;
    text-align:center;
    @media (min-width:992px) {
        text-align:left;
    }
`
const Link = styled.a`
    color: ${props => props.theme.color.blue.dark};
    font-weight:bold;
`