import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

// Components
import Wrapper from './wrapper'
import Header from './header'

export default ({
    menuLinks
}) => {
    return (
        <Wrapper>
            <Header title={`Site Map`}/>
            <List>
                {menuLinks.map(({
                    name,
                    link
                }, index) => (
                    <Item key={index}>
                        <SiteLink to={link}>{name}</SiteLink>
                    </Item>
                )) }
            </List>
        </Wrapper>
    )
}

// Styled Components
const List = styled.ul`
    list-style:none;
    margin:0;
    padding:0;
    display:grid;
    grid-row-gap: 0.75rem;
    align-content: start;
    grid-template-columns:repeat(2, minmax(0, 1fr));
    @media (min-width:992px) {
        grid-template-columns: minmax(0, 1fr);
    }
`
const Item = styled.li`

`
const SiteLink = styled(Link)`

`